import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import {
  calculateMonthlyRepayments,
  calculateTotalRepayment,
  calculateInterestOnlyMonthlyRepayments,
  calculateInterestOnlyTotalRepayment,
  calculateTotalInterest,
  calculateYearlyMortgageDetails,
} from "./calculations";
import SummaryComponent from "./SummaryComponent";
import ChartDouble from "./ChartDouble";

export default function ResultsDouble(settedData) {
  ResultsDouble.propTypes = {
    settedData: PropTypes.object.isRequired,
  };

  const data = settedData;
  //console.log(data);

  const monthlyRepayments =
    data.settedData.mortgageType === "repayment"
      ? calculateMonthlyRepayments(
          data.settedData.mortgageAmount,
          data.settedData.interestRate,
          data.settedData.loanTermText
        )
      : calculateInterestOnlyMonthlyRepayments(
          data.settedData.mortgageAmount,
          data.settedData.interestRate
        );

  const totalRepayment =
    data.settedData.mortgageType === "repayment"
      ? calculateTotalRepayment(monthlyRepayments, data.settedData.loanTermText)
      : calculateInterestOnlyTotalRepayment(
          data.settedData.mortgageAmount,
          monthlyRepayments,
          data.settedData.loanTermText
        );

  const totalInterest = calculateTotalInterest(
    totalRepayment,
    data.settedData.mortgageAmount
  );

  const mortgageData = calculateYearlyMortgageDetails(
    data.settedData.mortgageAmount,
    data.settedData.loanTermText,
    monthlyRepayments,
    data.settedData.interestRate
  );

  const monthlyRepayments2 =
    data.settedData.mortgageType2 === "repayment"
      ? calculateMonthlyRepayments(
          data.settedData.mortgageAmount2,
          data.settedData.interestRate2,
          data.settedData.loanTermText2
        )
      : calculateInterestOnlyMonthlyRepayments(
          data.settedData.mortgageAmount2,
          data.settedData.interestRate2
        );

  const totalRepayment2 =
    data.settedData.mortgageType2 === "repayment"
      ? calculateTotalRepayment(monthlyRepayments2, data.settedData.loanTermText2)
      : calculateInterestOnlyTotalRepayment(
          data.settedData.mortgageAmount2,
          monthlyRepayments2,
          data.settedData.loanTermText2
        );

  const totalInterest2 = calculateTotalInterest(
    totalRepayment2,
    data.settedData.mortgageAmount2
  );


  const mortgageData2 = calculateYearlyMortgageDetails(
    data.settedData.mortgageAmount2,
    data.settedData.loanTermText2,
    monthlyRepayments2,
    data.settedData.interestRate2
  );

  const combinedData = [...mortgageData, ...mortgageData2];
  const yearsSet = new Set(combinedData.map((entry) => entry.year));
  const yearsArray = Array.from(yearsSet).sort((a, b) => a - b);

  const chartData = yearsArray.map((year) => {
    const entry1 = mortgageData.find((entry) => entry.year === year);
    const entry2 = mortgageData2.find((entry) => entry.year === year);

    return {
      year: year,
      remainingLoanAmount1: entry1
        ? Math.round(entry1.remainingLoanAmount)
        : null,
      remainingLoanAmount2: entry2
        ? Math.round(entry2.remainingLoanAmount)
        : null,
    };
  });

  //console.log(chartData)

  const maxYValue =
    Math.ceil(
      Math.max(
        ...chartData.map((entry) =>
          Math.max(
            entry.remainingLoanAmount1 !== null
              ? entry.remainingLoanAmount1
              : 0,
            entry.remainingLoanAmount2 !== null ? entry.remainingLoanAmount2 : 0
          )
        )
      ) / 25000
    ) * 25000;

  return (
    <>
    <Box sx={{mt: 7, mb:4}}>
      <SummaryComponent
        summaryHeading={"Mortgage 1 Summary"}
        monthlyRepayments={monthlyRepayments}
        loanTermText={data.settedData.loanTermText}
        mortgageAmount={data.settedData.mortgageAmount}
        totalRepayment={totalRepayment}
        totalInterest={totalInterest}
        summaryBackgroundColor={"#19899D"}
      /><br/>
      <SummaryComponent
        summaryHeading={"Mortgage 2 Summary"}
        monthlyRepayments={monthlyRepayments2}
        loanTermText={data.settedData.loanTermText2}
        mortgageAmount={data.settedData.mortgageAmount2}
        totalRepayment={totalRepayment2}
        totalInterest={totalInterest2}
        summaryBackgroundColor={"#6E347A"}
      />
    </Box>
      <ChartDouble chartData={chartData} maxYValue={maxYValue} />
    </>
  );
}
