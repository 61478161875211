import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../../App";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import FormDivider from "../../../components/divider/FormDivider";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#FFF",
          borderColor: "#D80080",
          border: "1px solid",
          padding: "5px",
        }}
      >
        <p style={{ color: "#3e3e3e" }}>{`Year: ${label}`}</p>
        {payload.map((item) => (
          <p key={item.dataKey} style={{ color: "#3e3e3e" }}>
            {`${item.name}: ${item.value.toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default function ChartDouble({ chartData, maxYValue }) {
  const desktop = useContext(DesktopContext);
  return (
    <>
      <FormDivider dividerText={"Mortgage Debt Over Time"} />

      <ResponsiveContainer width="100%" height={desktop ? 400 : 350}>
        <LineChart
          data={chartData}
          margin={{ top: 30, right: 0, left: -29, bottom: 50 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            type="number"
            ticks={chartData.map((entry) => entry.year)}
            domain={["dataMin", "dataMax"]}
            tick={{ fontSize: 10, fill: "#3e3e3e" }}
          />
          <YAxis
            interval={0}
            ticks={Array.from(
              { length: maxYValue / 50000 + 1 },
              (_, i) => i * 50000
            )}
            domain={[0, maxYValue]}
            tick={{ fontSize: 10, fill: "#3e3e3e" }}
            tickFormatter={(tick) => `${tick / 1000}K`}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            height={36}
            wrapperStyle={{ color: "#3e3e3e", fontWeight: 500 }}
          />
          <Line
            type="monotone"
            dataKey="remainingLoanAmount1"
            name="Mortgage 1"
            stroke="#19899D"
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="remainingLoanAmount2"
            name="Mortgage 2"
            stroke="#6E347A"
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

ChartDouble.propTypes = {
  chartData: PropTypes.array.isRequired,
  maxYValue: PropTypes.number.isRequired,
};
